import React from "react";
import FarmerImg from "../../images/gbrPN/PNImg1.webp";

import FarmersFranchiseImg from "../../images/gbrPN/PNImg5.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";

export default function LogisticProvidersACNServices(){
    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="MoDBanner text-white"
         bannerText="ACN Services"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
        <div className="main-wrapper2">
            <div className="subSectionWrapper ">
        <h2 className="container-title text-center mt-4">
          Agri Chain Network Operator{" "}
        </h2>

        <HomeSubSection
        id="ABN-Transporters"
        className3="image5"
        className="rowReverseAbout--mod borderedCards"
        description="ABN collaborates seamlessly with Logistic Providers through the ABN Logistics Feature. This innovative platform empowers logistics companies and their teams to efficiently manage their entire fleet, including booking details, trip scheduling, and load coordination. ABN Platform facilitates smooth interactions between logistics providers and their drivers, whether in-house or third-party, streamlining the transportation process and enhancing overall operational efficiency within the agricultural supply chain.
        "
        // description="ABN Platform provides logistics faeture. With ABN Platform, logistics companies and their staff can manage their entire fleet, booking details, trips scheduling, scheduling loads & orders to their own drivers or 3rd party drivers."
        descriptionTitle=" Logistic Providers"
        src={MSMEImg}
        
        // href1="auth/13/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore  Logistic Providers" : "Sign In"
        // } `}
        // href3="/logistic-providers"
        // buttoncont3="Explore More"
        // onClick={() => {
        //   localStorage.setItem("userType", "LogisticProvider");
        // }}
        description1= "Global Logistic Providers | Hyperlocal Transporters | Cold Chain Logistics Providers "
      />
</div>
</div>
<div className="network-wrapper">

        <h2 className="container-title text-center mt-4">
          Agri Chain Network Members{" "}
        </h2>
        {/* <h4 className="container-title text-center mx-4">
        Production Network (PN) - Investable Asset Management network for MSMEs
      </h4>
      <p className="container-para text-center mx-md-0 mx-4 mb-4 ">MSME can Build, Own, Manage & Operate Production Network</p> */}

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="space-owner-img"
          description="A space owner is an individual or entity responsible for owning and operating a space, which is a facility designated for storing motor vehicles. These owners, who can be individuals, businesses, or organizations, oversee the management, maintenance, and security of the space to ensure its smooth operation.




          "
          descriptionTitle="
          Space Owner"
       
          // href2="auth/3/signin"
          // buttoncont2="Sign in"
          // buttoncont7="Sign up"
          // onClick={() => {
          //   localStorage.setItem("userType", "Grower");
          // }}
          // href7="/auth/3/signup"
          // descriptionTitle2= "What our plant "
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
        Space Operator"
          description="
          	A space operator plays a crucial role in the logistics and supply chain management industry. Essentially, a space operator manages and oversees the operations of a space, which is a facility or storage area used for the temporary storage, handling, and distribution of goods, products, or materials. Space operators are responsible for ensuring the efficient and organized flow of goods in and out of the space.  "
        
          className3="space-operator-img"
          
        />

     



        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="logistic-manger-img"
          description="	A Logistics Manager is a key professional responsible for the planning, coordination, and execution of a company's logistics and supply chain operations. This role is critical in ensuring the efficient movement of goods and materials from suppliers to customers, all while optimizing costs, maintaining quality, and meeting delivery deadlines."
          descriptionTitle="
          Logistic Manager"
          src={FarmerImg}
    
        />
<HomeSubSection
descriptionTitle="Driver"
          className="rowReverseAbout--mod  borderedCards borderedCards--mod"
          className3="driver-img"
                    description="
          A driver for the transportation of goods is a professional responsible for the safe and efficient movement of various types of cargo from one location to another. These drivers play a critical role in the logistics and supply chain industry, ensuring that goods are delivered on time and in good condition."         
        
          // description1="The ABN Plant operator communicates project status updates to both the Plant Manager and the Plant Executive, facilitating effective coordination and decision-making within the plant's management hierarchy."
        />
        <HomeSubSection
          className1="aboutBgGrey"
          className3="transport-user-img"
          className="rowReverseAbout borderedCards borderedCards--mod"
          descriptionTitle="
        Transport User"
          description="A Transport User refers to an individual or entity with their own fleet of vehicles, capable of participating in enterprises to support their operations. In scenarios where a Broker's vehicles are unavailable, they can utilize Transport User services to meet their transportation needs effectively.
      "
          src={FarmersFranchiseImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          // href1="auth/3/signin"
          // buttoncont1="Sign In"

          // href7='http://cbn.gbrapp.com/'
   
        />

      </div>



        <FooterComponent/>
        </>
    )
}