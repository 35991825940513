import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function SocialMarketNavbar() {
    const navData = [
    {to:"/socialonMarketplace", link:"For Farm Enterprise"},
    {to:"/ContractFarming", link:"For Brands"},
  ]
//   const navData2 = [{to:"/AgriBusiness", link:"Agri-Business"},
//   {to:"/Brands", link:"Brands"},
// ]
  return (
    <><SecondaryNavbar props={navData}  /></>
  )
}
