import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ClubMainPage from '../Club/ClubMainPage';
import "./AgriBusiness.css"
import SocialMarketNavbar from '../SocialMarketNavbar/SocialMarketNavbar';
import CFJourney from '../ForAAMC/CFJourney';

export default function AgriBusiness() {
  const userTypes= ["FOOD-PROCESSOR"]

   
 
  return (
    <>
    <HeaderComponent className="noShadow"/>
    <SocialMarketNavbar/>
    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText={["Start your journey  and introduce yourself to a Food Brands and Processors   ",<br className='d-md-block d-none'></br>, " by submitting your services brochure (Catalogue )  "]}

        /> 
        <div className='subSectionWrapper pb-0'>

<CFJourney/>
</div>
<div className='subSectionWrapper'>
<h2 className='container-title mb-md-5 text-center'>Brands</h2>

    <ClubMainPage type={userTypes} registerUser="AssetDeveloper" catalog="true"/>
    </div>
           <FooterComponent/>
    </>
  )
}
