import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./AgriMinerComponent.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import ArrowImg from "../../images/arrow6.png";
import agriMinerImg from "../../images/agriminer-signin.jpg";
import step1 from "../../images/step1.png";
import step2 from "../../images/step2.png";
import step3 from "../../images/step3.png";
import step4 from "../../images/step4.png";
import step5 from "../../images/step5.png";
import benefit1 from "../../images/beneifit1.png";
import benefit2 from "../../images/beneifit2.png";
import benefit3 from "../../images/beneifit3.png";
import benefit4 from "../../images/beneifit4.png";
import benefit5 from "../../images/beneifit5.png";
import Button from "@material-ui/core/Button";
import StepCards from "../stepCards/StepCards";
import GridCards from "../gridCards/GridCards";
import { NavLink } from "react-router-dom";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default class AgriMinerComponent extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     anchorEl: null,
  //     selectedSlide: 0,
  //     phoneNumber: "",
  //   };
  // }

  // componentDidMount() {}
  // componentDidUpdate(prevProps) {
  //   if (prevProps.sliderRef === this.props.sliderRef) return;
  //   setTimeout(this.showSliderContent, 300);
  //   if (this.state.selectedSlide === this.props.selectedSlide) return;
  //   this.setState({ selectedSlide: this.props.selectedSlide });
  // }

  // onTransitionEnd(value) {
  //   if (this.state.selectedSlide === value.currentIndex) return;
  //   this.setState({ selectedSlide: value.currentIndex });
  // }
  // showSliderContent = () => {
  //   this.sliderContent.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //   });
  // };
  // handleChange(evt) {
  //   this.setState({ [evt.target.name]: evt.target.value });
  // }

  stepCardContent = [
    {
      title: "Step 1",
      bodycopy: "Download, install and Login to the App.",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy: "Upload the farm enterprise business details.",
      img: step2,
    },
    {
      title: "Step 3",
      bodycopy: "Wait for the approval from FEN representative.",
      img: step3,
    },
    {
      title: "Step 4",
      bodycopy:
        "Once approved by FEN, you can check the earnings in your wallet. ",
      img: step4,
    },
    {
      title: "Step 5",
      bodycopy:
        "Withdraw money anytime from Wallet to your verified Bank Account.",
      img: step5,
    },
  ];

  benefits = [
    // {
    //   img: benefit1,
    //   title: "Be Your Own Boss",
    //   bodycopy:
    //     "Work for yourself and have more control and flexibility over your work activities and finance.",
    // },
    // {
    //   img: benefit2,
    //   title: "Start earning with Zero Investment",
    //   bodycopy:
    //     "Download, install, open and register with FEN Platform to start earning without any investment.",
    // },
    {
      img: benefit3,
      title: "Earn anytime from anywhere",
      bodycopy:
        "Download, install, open and register with FEN Platform to start earning from anywhere.",
    },
    // {
    //   img: benefit4,
    //   title: "Contribute to agri-food business growth",
    //   bodycopy:
    //     "Your work helps to rebuild agri-food business network and enables digital transformation.",
    // },
    {
      img: benefit5,
      title: "Contribute to farm enterprise business growth",
      bodycopy:
        "Your work helps to grow farm enterprises business and enables digital transformation for them.",
    },
  ];

  render() {
    return (
      <>
        <HeaderComponent />
        {/* <div className="businessPartnerContainer AgriMinorContainer">
          <div data-src="assets/img/bgImg.jpg" className="imageCnt">
            <h3>START EARNING WITH NO INVESTMENT</h3>
            <p>Earn round the clock by becoming AgriMiner</p>
            <Button variant="contained" color="primary" className="investNowButton">
              <a href="/sms">Start Now</a>
            </Button>
            <br />
            <p className="storeButtons">
              <Button className="whiteTextColor">
                <a href="https://play.google.com/store">
                  <img
                    alt=""
                    src="./assets/img/icons/googlestore.png"
                    height="30"
                    width="90"
                  />
                </a>
              </Button>
            </p>
          </div>
        </div> */}


        <UpdatesBanner 
         bannerText={[
          "Start Earning With No Investment",
        ]}
className="AgriMinorContainer"
        bannerbodycopy="Earn round the clock by becoming Agri Industry Advisor"
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&hl=en_US"
        DownloadText="Download App"
        marginfalse="true"/>        <div className="agriminer-wrapper">
          <Container className="text-center who-are-agriminer-container">
            <h4 className="container-title mb-3">
              Who are an Agri Industry Advisors
            </h4>
            <p className="container-para px-auto px-md-5 mb-3">
              Experts in the agricultural sector, offering expertise on farming
              practices, crop and livestock management, sustainability, and
              supply chain optimization. They primarily work with farmers,
              agribusinesses, and agricultural organizations to improve
              productivity and profitability in farming operations.{" "}
            </p>

            <p>
              Agronomists | Veterinarians | Agro-economists | Sustainability
              Consultants | Sustainability Consultants | Technology Specialists
              | Policy Advisors
            </p>
          </Container>
          <div className="subSectionWrapper main-wrapper2">

          <Container>

            <h4 className="benefit-title container-title  text-center">
              Agri Industry Advisors Benefits
            </h4>

            

            <div className="section3BodyContainer benefit-container justify-content-md-center p-0">
              {this.benefits.map((e) => {
                return (
                  <StepCards
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                );
              })}
            </div>
          </Container>
          </div>
          {/* <Container
          className="InvestmentContainer SignUpasContainerWhite"
          style={{ paddingTop: 80 }}
        >
          <h4 className="container-title">AGRIMINER BENEFITS!</h4>
          <div className="gridItemContainer">
            <div className="gridItem">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b>Be Your Own Boss</b>
                </h4>
                <p className="container-para">
                  Work for yourself and have more control and flexibility over
                  your work activities and finance.
                </p>
              </div>
            </div>

            <div className="gridItem ContainerWhite">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b>Start Earning with Zero Investment</b>
                </h4>
                <p className="container-para">
                  Download, install, open and register with FEN Platform to
                  start earning without any investment.
                </p>
              </div>
            </div>

            <div className="gridItem">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b>Earn Anytime from anywhere</b>
                </h4>

                <p className="container-para">
                  Download, install, open and register with FEN Platform to
                  start earning from anywhere.
                </p>
              </div>
            </div>

            <div className="gridItem ContainerWhite">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b>Contribute to Farmer Growth</b>
                </h4>
                <p className="container-para">
                  Your work helps to rebuild farmer communities and enables
                  digital transformation in Agriculture
                </p>
              </div>
            </div>

            <div className="gridItem">
              <div className="gridItem-body">
                <h4 className="container-heading2">
                  <b> MAKE MORE PASSIVE INCOME THAN EVER:</b> <br />
                </h4>
                <p className="container-para">
                  Easy and quick way to make passive income by refering farmers,
                  bucthers and other FEN partners of your locality
                </p>
              </div>
            </div>
          </div>
        </Container> */}

          <Container className=" agriminor-journey-container">
            <h4 className="container-title">Agri Industry Advisors Journey</h4>

            <div className="section3BodyContainer justify-content-md-center p-0">
              {this.stepCardContent.map((e) => {
                return (
                  <StepCards
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                );
              })}
            </div>
          </Container>

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod pt-0"
            description="             Experts in the agricultural sector, offering expertise  on farming practices, crop and livestock management, sustainability, and supply chain optimization. They primarily work with farmers, agribusinesses, and agricultural organizations to improve productivity and profitability in farming operations.      
            "
            description1="Agronomists | Veterinarians | Agro-economists | Sustainability Consultants | Sustainability Consultants | Technology Specialists | Policy Advisors

            "
            descriptionTitle="
        Sign up as Agri Industry Advisors !"
            src={agriMinerImg}
            href2="/auth/3/signup"
            buttoncont2="Register"
            onClick={() => {
              localStorage.setItem("userType", "AgriMiner");
            }}
          />
        </div>
        <FooterComponent />
      </>
    );
  }
}
