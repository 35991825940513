
import React from "react";
import HoneyAsset from "../../images/1 (1).png";
import HoneyAsset1 from "../../images/forMSMEBanner.webp";
import Community1 from "../../images/transparent-plastic-bottles-filled-with-yellow-substance (4).jpg";

import Community from "../../images/forMSMEBanner.webp";
import FieldSupport from "../../images/socialmarketplace.jpg";
import ProcessingSupport from "../../images/bee-nests-flower-garden.jpg"
import OnBoardSupport from "../../images/young-agronomist-showing-some-information-farmer-smartphone-greenhouse-min.jpg"
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import businessDiverstification from "../../images/business-growth-bg.jpg";

export default function AgriFoodManufacturerersServices() {
  const services = [
    // {
    //     title: "CMN",
    //     image: HoneyAsset1,
    //     href: "/cmn-services",
    //   },
   
    {
        title: "onMarketplace",
        image: HoneyAsset,
        href: "/onMarketplace",
      },

      {
        title: "Social onMarketplace",
        image: FieldSupport,
        href: "/socialonMarketplace",
      },

    //   {
    //     title: "Social iaaMarketplace",
    //     image: ProcessingSupport,
    //     href: "/iaaMarketplace",
    //   },

      // {
      //   title: "CM onboarding service",
      //   image: OnBoardSupport,
      //   href: "/cm-onboarding",
      // },
      // {
      //   title: "Manufacturing onDemand (MoD)",
      //   image: Community1,
      //   href: "/manufacturing-on-demand",
      // },

      {
        title: "Billing Services",
        image: OnBoardSupport,
        href: "/billing-service",
      },
      // {
      //   title: "Business Diversification Services",
      //   image: businessDiverstification,
      //   href: "/business-diversification-service",
      //   customPropValue: "FE"
      // },
  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper  pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {

          const navLinkProps = {
            to:{
pathname: e.href
            }
          }

          if(e.customPropValue){
            navLinkProps.to.state = {customProp:e.customPropValue}
          }
          return (
            <Col md={5}>
              <NavLink {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
