import React from "react";
import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import FarmEnterpriseCustomerBenefits from "../ForAAMC/FarmEnterpriseCustomerBenefits";

export default function FECapitalProviders(props) {
  const exploreButton = props.exploreBtn;
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="AAMCBanner" bannerText="FE Capital Providers"/>
    <Container><FarmEnterpriseCustomerBenefits/></Container>

    <div className="subSectionWrapper main-wrapper2">
      <Container>
        <h4 className="container-title text-center mx-md-4 ">
          
        Capital Providers
        </h4>

     
      </Container>
   

 
      <HomeSubSection
        id="FEN-Investor"
        className3="image2"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Institutional Lender

        "
        description="Institutional Lenders are capital providers for farm enterprises, offering financial resources such as loans and credit to support their growth and operations. This access to capital enables farm enterprises to invest in equipment, inputs, and expansion.


        "
        src={InvestorImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/17/signin"
        // buttoncont1={`${exploreButton ? "Explore Agri Investors" : "Sign In"} `}
        exploreButton={exploreButton}
        href3="/explore-institutional-lenders"
        buttoncont3="Explore More"
       onClick={() => {
          localStorage.setItem("userType", "AssetOEM");
        }} 
        description1="Banks | NBFCs | Angel Investors | Institutional Investors

        "
      />

<HomeSubSection
        id="FEN-Investor"
        className3="image2"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Agri-retailer Investors"
        description="Agri-Retailer Investors are capital providers for farm enterprises, supplying funding to help them purchase agricultural inputs and expand their operations. Their investment enables farm enterprises to enhance productivity and scale their businesses.
        "
        src={InvestorImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/17/signin"
        // buttoncont1={`${exploreButton ? "Explore Agri Investors" : "Sign In"} `}
        exploreButton={exploreButton}
        href3="/explore-agri-retailers"
        buttoncont3="Explore More"
       onClick={() => {
          localStorage.setItem("userType", "AssetOEM");
        }} 
        // description1="Banks | NBFCs | Angel Investors | Institutional Investors | Retail Investors"
      />

    
      
    




    </div>
    <FooterComponent/>
    </>

  );
}
