import React from 'react'
import AboutList from './AboutList'

export default function ProductionService() {
    const data=[
  "onMarketplace:  onMarketplace is a marketplace provided by FEN Network for buying and selling agricultural products to a wider consumer."  ,
"Social onMarketplace:   Social onMarketplace is a platform provided by FEN Network that integrates social features for farm enterprisees and brands, facilitating interaction and commerce within the agricultural community.",
// "AgroCare Marketplace:  Agrocare Marketplace, provided by FEN Network, offers a range of animal and plant care products, catering to the needs of agricultural businesses and enthusiasts.",
"Social iaaMarketplace: Social iaaMarketplace, provided by FEN Network, is a platform designed for farm enterprisees and investors to interact, collaborate, and conduct transactions within the agricultural sector."
       
    ]
  return (
   <AboutList title="Marketplace" contentlist={data}/>
  )
}