import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "react-bootstrap";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import "./LogisticsProviders.css";
import LogisticsProvidersServices from "./LogisticsProvidersServices";
import LogisticsProvidersBenefits from "./LogisticsProvidersBenefits";
import CBOPricing from "../pricingTabs/CBOPricing";
export default function LogisticsProviders() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="logistic-provider-bg"
        bannerText="Logistc Providers"
      />
      {/* <div className="main-wrapper2">
        <LogisticProvidersChallenges />
      </div> */}
      <Container>
        <SolutionEcosystem
          bold="true"
          type="LP"
          bodycopy="FEN Network provides an ecosystem for logistic Providers to ovecome the challenges."
        />
        {/* <TitleDescription
    classname="mb-0"
    classname2="mb-0"
          title="About Brands"
          description="GBR Farming specializes in building and enhancing brands, offering expertise in packaging organic and traceable products. Whether creating new brands or improving existing ones, we are dedicated to crafting unique and trustworthy identities for agricultural products that meet the highest standards of quality and sustainability."/>   */}
      </Container>
      <div className="main-wrapper2">
        <Container>
          <LogisticsProvidersServices />
        </Container>
      </div>

      <Container className="mt-4">
        <LogisticsProvidersBenefits />
      </Container>
      {/* <Container>
        <h2 className="container-title text-center "> Pricing</h2>

        <CBOPricing />
      </Container> */}
      {/* <div className="subSectionWrapper pt-0">
        <Container>
              <CTA
                // data="present"
                href="/auth/3/signup"
                heading="Optimize Your Logistics Today with GBR Network's Advanced Solutions!"
                btnText="Register now for free"
                onClick={()=>{
                  localStorage.setItem("userType", "LogisticProvider")
                              }}
              />
              </Container>
            </div> */}
      <FooterComponent />
    </>
  );
}
