import React from "react";
import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import FEPartnersBenefit from "../ForAAMC/FEPartnersBenefit";


export default function FEPartner(props) {
  const exploreButton = props.exploreBtn;
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="AAMCBanner" bannerText="FE Partners"/>
    <Container>
    <FEPartnersBenefit/>
    </Container>
    <div className="subSectionWrapper main-wrapper2">
      <Container>
        <h4 className="container-title text-center mx-md-4 ">
          
          Partners
        </h4>

     
      </Container>
   

 


    
      <HomeSubSection
        id="FEN-Advertisement"
        className3="image3"
        className="rowReverseAbout borderedCards"
        description="Agri Input Providers are key partners of farm enterprises, supplying essential agricultural inputs like seeds, fertilizers, and equipment. Their support ensures that farm enterprises have the necessary resources to optimize production and achieve higher yields.
                "
        descriptionTitle="
                Agri Input Providers"
        description1="FMC | PMC | AHSP | AIC"
        src={MachineryImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/20/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Machinery Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/farm-machinery-providers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />

    




 
      <HomeSubSection
        id="FEN-Transporters"
        className3="image5"
        className="rowReverseAbout--mod borderedCards"
        description="Logistic Providers are partners of farm enterprises, responsible for transporting agricultural products and inputs. They ensure efficient and timely delivery, supporting smooth operations across the supply chain.
        "
        // description="FEN  Platform provides logistics faeture. With FEN  Platform, logistics companies and their staff can manage their entire fleet, booking details, trips scheduling, scheduling loads & orders to their own drivers or 3rd party drivers."
        descriptionTitle=" Logistic Providers"
        src={MSMEImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/13/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore  Logistic Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/logistic-providers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "LogisticProvider");
        }}
        description1= "Global Logistic Providers | Hyperlocal Transporters | Cold Chain Logistics Providers "
      />


    
      {/* <HomeSubSection
        id="FEN-Govt"
        className3="image6"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Government Organizations"
        description="FEN  provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
        description1="Local Goverments | Central Goverments | Other Organizations"
        src={GovernmentOrganizationImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/agency"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "GovtDept");
        }}
      /> */}
   
   {/* <HomeSubSection
            className3="image55"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Producers"
            description="FEN  provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
            description1="Local Goverments | Central Goverments "
            src={GovernmentOrganizationImg}
            href2="auth/3/signup"
            buttoncont2="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/ComingSoon"
            buttoncont3="Explore More"
            onClick={() => {
              localStorage.setItem("userType", "GovtDept");
            }}
          /> */}
      





   


    
    










      
     



{/* <HomeSubSection
        id="FEN-Advisory"
        className="rowReverseAbout--mod borderedCards"
        className3="image1"
        description="Advisors at FEN  are essential partners, providing expertise in animal health, plant health, and machinery. Their insights ensure the well-being of livestock, optimize crop management, and maintain efficient machinery operations. Committed to excellence, these advisors play a pivotal role in FEN 's holistic approach to agriculture, contributing to overall farm success.
                "
        descriptionTitle="
                Advisors"
        src={AdvisorImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1=  "auth/19/signin"
        // buttoncont1= "Sign In"
        exploreButton={exploreButton}
        href3="/advisors"
        buttoncont3="Explore More"
        description1="Consultants | Agronomist | Vet | Certification agencies "
        onClick={() => {
          localStorage.setItem("userType", "MSME-ADVISOR")
        }}
      /> */}
            
{/* <HomeSubSection
            className3="technology-provider-image"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Technology Providers"
            description="FEN  is proud to partner with leading technology providers to advance our agricultural practices. This collaboration integrates state-of-the-art technologies into our farming operations, enhancing efficiency, precision, and overall productivity. The expertise and innovative solutions provided by our technology partners contribute significantly to our success. Together, FEN  and our technology partners are dedicated to leveraging the latest advancements to ensure sustainable and cutting-edge practices in modern agriculture.
            "
            description1="IoT Solution Providers | CRM & ERP Providers | Irrigation Providers | Satellite Imagery provider | Device Makers"
            href2="auth/3/signup"
            buttoncont2="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/ComingSoon"
            buttoncont3="Explore More"
          /> */}



{/* <HomeSubSection
        id="FEN-Investor"
        className3="agro-dealer-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards--mod"
        descriptionTitle="
        Agro-Dealers"
        description="Agro-dealers serve as vital intermediaries, connecting agri-retailers and farmers with crucial agricultural inputs and services at the local level. They offer a range of resources, including seeds, fertilizers, and pesticides, while also providing technical support, credit facilities, and market access. Through these services, agro-dealers contribute significantly to improving agricultural productivity, empowering farmers with the necessary tools and knowledge for sustainable and profitable farming practices.
        "
        description1="Agro-Dealers | Agro-Retailers | Veterinary | Agronomist | AIC-Dealers | AHSP-Dealers | PMC-Dealers | FMC-Dealers"
        href3="/agro-dealers"
        buttoncont3="For Agro-Dealers"
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "AHSP");
            }}
       
      /> */}
{/* <HomeSubSection
        id="FEN-Investor"
        className3="cbo-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Community Based Organizations"
        description="Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues  in farming, thereby promoting resilient and inclusive agricultural systems at the local level.
        "
        description1="FPCs | NGOs | PACS | Traders | SHG | CS"
        href3="/CBO"
        buttoncont3="For Community Based Organizations"
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "CBO");
            }}      /> 









<HomeSubSection
        id="FEN-Investor"
        className3="agri-foodmanufacturer-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Food Processing Enterprises"
        description="Food Processing Enterprises are companies involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods.
        "
        description1="Food processor "
        href3="/food-processing-enterprises"
        buttoncont3="For Food Processing Enterprises        "
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "FOOD-PROCESSOR");
            }}
      /> */}
    </div>
<FooterComponent/>
    </>
  );
}
