import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
// import "./OnMarketplaceSell.css";
import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";

import { useLocation, useHistory } from "react-router-dom";

export default function BulkOnMarketplaceSell(){
  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;
  let navData = [
    { to: "/bulk-onmarketplace", link: "For B2B Buyers",  },    { to: "/bulk-onmarketplace-sell", link: "For Sellers" }

    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

  // Conditionally include customPropValue in navData
  if (customPropValue) {
    navData = [

      { to: "/onMarketplace", link: "For B2C Buyers", customPropValue },
      { to: "/onMarketplace-b2b-buyers", link: "For B2B Buyers",  },
      // { to: "/onMarketplace", link: "For Buyers", customPropValue },
      { to: "/onMarketplaceSell", link: "For Sellers" }
      // { to: "/onMarketplaceRFQ", link: "RFQ" }
    ];
  }

    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling Organic products with FEN"
         buttonText="Sign Up"
         href="auth/3/signup"
        />
        <MarketPlaceQuotePP/>
        <FooterComponent/>
        </>
    )
}