import React from 'react'
import AboutList from './AboutList'

export default function Network() {
    const data=[
  "CBN:  The Community Based Network is a platform provided by FEN Network to farm enterprise for effectively managing and coordinating agri-producers within their network ."  ,
"DTN:   The Digital Transformation Network is a network provided by FEN Network to farm enterprise for managing Agri-MSMEs and facilitating their digital transformation journey within the agricultural sector.",

       
    ]
  return (
   <AboutList title="Network" contentlist={data}/>
  )
}