import React from "react";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import Icon1 from "../../images/CustomerBenefit/productivity.svg"
import Icon2 from "../../images/CustomerBenefit/reputation-management.svg"

import Icon3 from "../../images/CustomerBenefit/productivity (1).svg"
import Icon4 from "../../images/CustomerBenefit/traceability.svg";

export default function BuyerBenefits() {
  const benefits = [
   
    {
      image: Icon1,
       title: "Ensured Quality",
       bodycopy:
         "Assured quality is a benefit for F&B buyers through the FEN network, ensuring reliability and consistency in procured products. "
     },
 {
       image: Icon2,
       title: "Traceability ",
       bodycopy:
         "Traceability assures F&B buyers of the origin and journey of products through the FEN network, enhancing transparency, accountability, and confidence in sourcing practices."
     },
     
 
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">F&B Buyer Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
