import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "@material-ui/core";
import TitleDescription from "../TitleDescription/TitleDescription";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import "./ForBuyers.css";
import BuyerChallenges from "./BuyerChallenges";
import BuyerServices from "./BuyerServices";
import BuyerBenefits from "./BuyerBenefits";
import BuyerContribution from "./BuyerContribution";
import BrandPricingData from "../pricingTabs/BrandPricingData";
import CTA from "../CTA/CTA";

export default function ForBuyers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="buyers-background"
        bannerText={[
          "F&B Buyers",
        ]}
        bannerbodycopy="Ensured Quality | Traceability"
        // buttonText2="Sign in"
        // buttonText3="Sign up"
        // href2="https://abn.gbrapp.com/"

        // href3="https://abn.gbrapp.com/signup"   
           />

{/* <Container>
        <TitleDescription
          classname=""
          title="Who are F&B Buyers"
          description={[
            "F&B Buyers are integral partners for ABN network, forming a crucial link in the agri-food supply chain. These partners play a vital role in the success of ABN network by purchasing processed, semi-processed and raw products. The collaboration with buyers ensures a market for the  agri-products, contributing to financial sustainability and fostering a mutually beneficial relationship. This partnership highlights the interconnected nature of the agricultural ecosystem, where agri-producer, processor and buyers work together within ABN network guidelines to meet end users market demands and achieve overall success.",
            // <br className="d-md-block d-none"></br>,
            // "We help farm enterprises to become  Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.",
          ]}
        />
      </Container> */}

      {/* <div className="main-wrapper2">
        <BuyerChallenges/>
      </div> */}
      {/* <SolutionEcosystem
      bold="true"
        type="Buyers"
        bodycopy="System and Process for buyers."
      /> */}
       <div className="main-wrapper2">
        <Container>
        <BuyerServices />
        </Container>
      </div>
      <Container>
      <BuyerBenefits/>

      {/* <BuyerContribution/> */}
      </Container>
     
      <FooterComponent />
    </>
  );
}
